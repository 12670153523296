<template>
    <div v-if="isShow && match.is_show">
        
        <!-- <span class="text-sm" v-if="!match.start_time">{{ betCloseTime() }}</span> -->
        <!-- betting team card -->
        <div v-if="type !== 'history'" class="match-container mb-2 px-1 py-2" :class="match.is_big ? 'big-match-design' : ''">
            <span class="text-md mt-1 text-white pl-2" v-if="match.start_time"><span class="text-danger text-lg" v-if="match.is_big && $route.path !== '/maung-fb'"> * * </span> <span class="font-weight-bolder text-md" v-if="match.league">{{ match.league }} </span>
            <span class="text-danger" v-if="match.is_big && $route.path !== '/maung-fb'">
                            ( {{
                                $store.state.language === "en"
                                    ? "BIG MATCH"
                                    : "ပွဲကြီး"
                            }} )</span>
            ( {{ betTime(match.start_time) }} )</span><br>
            <div
                class="upper-card w-100 d-flex justify-content-around"
                style="height: 40px"
            >
                <div
                    class="upper-left-card d-flex"
                    style="width: 50%; border-radius: 10px;"
                    :class="
                        ((betMatch.selected_team === match.first_team) && (!isGoalPor && !isSoneMa))
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.first_team, null, match.first_team_id)
                    "
                >
                    <span class="my-auto mx-auto"
                        >
                        <b>
                            {{
                                match.first_team
                            }}</b
                        >
                        </span
                    >
                    <div
                        class="mx-auto my-auto badge badge-info"
                        style="font-size: 12px; color: white; background: #0084C7 !important; letter-spacing: 2px;"
                        v-if="
                            match.kyayed_team === match.first_team &&
                            !isKyayHistory
                        "
                    >
                        {{
                            getFBOrder.type === "maung"
                                ? match.maung_kyay === "~"
                                    ? "=="
                                    : match.maung_kyay
                                : match.body_kyay === "~"
                                ? "=="
                                : match.body_kyay
                        }}
                    </div>
                </div>

                <div
                    class="upper-right-card d-flex"
                    style="width: 50%; border-radius: 10px;"
                    :class="
                        ((betMatch.selected_team === match.second_team) && (!isGoalPor && !isSoneMa))
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.second_team, null, match.second_team_id)
                    "
                >
                    <div
                        class="mx-auto my-auto badge badge-info"
                        style="font-size: 12px; color: white; background: #0084C7 !important; letter-spacing: 2px;"
                        v-if="
                            match.kyayed_team === match.second_team &&
                            !isKyayHistory
                        "
                    >
                        {{
                            getFBOrder.type === "maung"
                                ? match.maung_kyay === "~"
                                    ? "=="
                                    : match.maung_kyay
                                : match.body_kyay === "~"
                                ? "=="
                                : match.body_kyay
                        }}
                    </div>
                    <span class="text-center my-auto mx-auto"
                        >
                        <b
                            >{{
                                match.second_team
                            }}</b
                        >
                        </span
                    >

                </div>
            </div>
            <div
                class="lower-card w-100 d-flex p-1 justify-content-center"
            >
                <div class="d-flex kyay-left">
                    <div
                        class="mx-auto my-auto goal-por pt-2 text-white"
                        style="font-size: 15px; color: red; border-radius: 10px;"
                        :class="
                            ((betMatch.selected_team === match.first_team) && isGoalPor)
                                ? 'active'
                                : null
                        "
                        v-if="!isKyayHistory"
                        @click="
                            type === 'history'
                                ? ''
                                : checkTeam(match.first_team, 'up', match.first_team_id)
                        "

                    >
                    <b>
                        {{
                            $store.state.language === "en" ? "Goal Up" : "ဂိုးပေါ်"
                        }}
                    </b>
                    </div>
                </div>

                <div
                    style="
                        width: 50px;
                        background: #02133E;
                        border-radius: 3px;
                        font-size: 15px;
                    "
                    class="text-center pt-1 text-danger text-white"
                    @click="type === 'history' ? '' : changeGoalPor()"
                    v-if="isKyayHistory"
                >
                    {{
                        getFBOrder.type === "maung"
                            ? history.maung_goal_kyay
                            : history.body_goal_kyay
                    }}
                </div>
                <div
                    style="
                        height: 32px;
                        background: #0084C7;
                        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                        border-radius: 7px;
                        font-size: 13px;
                        width: 70px;
                        letter-spacing: 2px;
                    "
                    class="text-center pt-2 text-danger text-white"
                    @click="type === 'history' ? '' : changeGoalPor()"
                    v-else
                >
                    {{
                        getFBOrder.type === "maung"
                            ? match.maung_goal_kyay
                            : match.body_goal_kyay
                    }}
                </div>
                <div class="d-flex kyay-right">
                    <div
                        class="mx-auto my-auto goal-por text-white pt-2"
                        style="font-size: 15px; border-radius: 10px;"
                        :class="
                            ((betMatch.selected_team === match.second_team) && isGoalPor)
                                ? 'active'
                                : null
                        "
                        v-if="!isKyayHistory"
                        @click="
                            type === 'history'
                                ? ''
                                : checkTeam(match.second_team, 'down', match.second_team_id)
                        "

                    >
                    <b>
                        {{
                            $store.state.language === "en" ? "Goal Down" : "ဂိုးအောက်"
                        }}
                    </b>
                    </div>
                </div>
            </div>

            <!-- SONE MA  -->
            <div
                class="upper-card w-100 d-flex justify-content-around"
                style="height: 40px"
            >
                <div
                    class="upper-left-card d-flex"
                    style="width: 50%; border-radius: 10px;"
                    :class="
                        ((betMatch.selected_team === match.first_team) && isSoneMa)
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.first_team, null, match.first_team_id, 'sone')
                    "
                >
                    <span class="my-auto mx-auto"
                        >
                        <b>
                            {{
                                $store.state.language === "en" ? "Even" : "စုံ"
                            }}</b
                        >
                        </span
                    >
                </div>

                <div
                    class="upper-right-card d-flex"
                    style="width: 50%; border-radius: 10px;"
                    :class="
                        ((betMatch.selected_team === match.second_team) && isSoneMa)
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.second_team, null, match.second_team_id, 'ma')
                    "
                >
                    <span class="text-center my-auto mx-auto"
                        >
                        <b
                            >
                            {{
                                $store.state.language === "en" ? "Odd" : "မ"
                            }}
                            </b
                        >
                        </span
                    >

                </div>
            </div>
            <!-- END SONE MA  -->
            
        </div>

        <!-- history team card -->
        <div v-if="type === 'history'" class="match-container mb-2">
            <div
                class="upper-card w-100 d-flex justify-content-around"
                style="height: 40px"
            >
                <!-- style="width: calc(50% - 100px)" -->
                <div
                    class="upper-left-card d-flex"
                    style="width: calc(50% - 40px)"
                    :class="
                        betMatch.selected_team === match.first_team
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.first_team, 'up', match.first_team_id)
                    "
                >
                    <span class="my-auto mx-auto" style=""
                        >
                        <!-- <b
                            >{{ firstMatchNo() }}.{{ match.first_team }}</b
                        > -->
                        <b
                            >{{
                                    $store.state.language === "en"
                                        ? match.first_team_en
                                        : match.first_team
                                }}</b
                        >
                        </span
                    >
                </div>
                <div class="d-flex" v-if="isGoalPor">
                    <i
                        style="font-size: 20px; color: red; margin-right: 2px"
                        class="fas fa-long-arrow-alt-up my-auto"
                    ></i>
                </div>
                <div
                    class="upper-middle-card d-flex justify-content-center"
                    style="width: 80px"
                >
                    <!-- <div>
                        <img
                            src="https://via.placeholder.com/35"
                            class="team-image"
                            alt=""
                        />
                    </div> -->
                    <div
                        class="text-center px-1"
                        :class="type === 'history' ? 'd-flex' : ''"
                        style="
                            width: 80px;
                        "
                    >
                        <!-- 0:0 -->
                        <div v-if="type !== 'history'"><b>Close</b></div>
                        <div v-if="type !== 'history'">
                            <!-- {{ match.close_time.hh }} :
                            {{ match.close_time.mm }} {{ match.close_time.A }} -->
                        </div>
                        <div
                            v-if="type === 'history'"
                            class="text-danger mx-auto my-auto"
                        >
                            <h2>
                                {{
                                    match.first_team_result +
                                    " : " +
                                    match.second_team_result
                                }}
                            </h2>
                        </div>
                    </div>
                    <!-- <div>
                        <img
                            src="https://via.placeholder.com/35"
                            class="team-image"
                            alt=""
                        />
                    </div> -->
                </div>
                <div class="d-flex" v-if="isGoalPor">
                    <i
                        style="font-size: 20px; color: red; color: red; margin-left: 2px"
                        class="fas fa-long-arrow-alt-down my-auto"
                    ></i>
                </div>
                <div
                    class="upper-right-card d-flex"
                    style="width: calc(50% - 40px)"
                    :class="
                        betMatch.selected_team === match.second_team
                            ? 'active'
                            : null
                    "
                    @click="
                        type === 'history'
                            ? ''
                            : checkTeam(match.second_team, 'down', match.second_team_id)
                    "
                >
                    <span class="text-center my-auto mx-auto"
                        >
                        <!-- <b
                            >{{ secondMatchNo() }}.{{ match.second_team }}</b
                        > -->
                        <b
                            >{{
                                $store.state.language === "en"
                                    ? match.second_team_en
                                    : match.second_team
                            }}</b
                        >
                        </span
                    >
                </div>
            </div>
            <div
                class="lower-card w-100 d-flex p-1 justify-content-center"
                style="height: 40px"
            >
                <div class="d-flex kyay-left pt-1">
                    <div
                        class="mx-auto"
                        style="font-size: 15px; color: red"
                        v-if="
                            match.kyayed_team === match.first_team &&
                            !isKyayHistory
                        "
                    >
                        {{
                            getFBOrder.type === "maung"
                                ? match.maung_kyay === "~"
                                    ? "=="
                                    : match.maung_kyay
                                : match.body_kyay === "~"
                                ? "=="
                                : match.body_kyay
                        }}
                    </div>
                    <div
                        class="mx-auto"
                        style="font-size: 15px; color: red"
                        v-if="
                            match.kyayed_team === match.first_team &&
                            isKyayHistory
                        "
                    >
                        {{
                            getFBOrder.type === "maung"
                                ? history.maung_kyay === "~"
                                    ? "=="
                                    : history.maung_kyay
                                : history.body_kyay === "~"
                                ? "=="
                                : history.body_kyay
                        }}
                    </div>
                </div>
                <div
                    style="
                        width: 70px;
                        background: #02133E;
                        border-radius: 3px;
                        font-size: 15px;
                    "
                    class="text-center pt-1 text-danger text-white"
                    @click="type === 'history' ? '' : changeGoalPor()"
                    v-if="isKyayHistory"
                >
                    Gp{{
                        getFBOrder.type === "maung"
                            ? history.maung_goal_kyay
                            : history.body_goal_kyay
                    }}
                </div>
                <div
                    style="
                        width: 70px;
                        background: #02133E;
                        border-radius: 3px;
                        font-size: 15px;
                    "
                    class="text-center pt-1 text-danger text-white"
                    @click="type === 'history' ? '' : changeGoalPor()"
                    v-else
                >
                    Gp{{
                        getFBOrder.type === "maung"
                            ? match.maung_goal_kyay
                            : match.body_goal_kyay
                    }}
                </div>
                <div class="d-flex kyay-right" style="width: calc(50%-25px)">
                    <div
                        class="mx-auto"
                        style="font-size: 15px; color: red"
                        v-if="
                            match.kyayed_team === match.second_team &&
                            !isKyayHistory
                        "
                    >
                        {{
                            getFBOrder.type === "maung"
                                ? match.maung_kyay === "~"
                                    ? "=="
                                    : match.maung_kyay
                                : match.body_kyay === "~"
                                ? "=="
                                : match.body_kyay
                        }}
                    </div>
                    <div
                        class="mx-auto"
                        style="font-size: 15px; color: red"
                        v-if="
                            match.kyayed_team === match.second_team &&
                            isKyayHistory
                        "
                    >
                        {{
                            getFBOrder.type === "maung"
                                ? history.maung_kyay === "~"
                                    ? "=="
                                    : history.maung_kyay
                                : history.body_kyay === "~"
                                ? "=="
                                : history.body_kyay
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';
// Import the Luxon library
import { DateTime } from 'luxon';

export default {
  //   props: ['match', 'type'],
  props: {
    match: Object,
    history: Object,
    type: String,
    onMyEvent: Function,
    isKyayHistory: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      targetTimeZone: 'Asia/Yangon',
      currentTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      isGoalPor: false,
      isSoneMa: false,
      interval: null,
      isShow: true,
      isLast: false,
      betMatch: {},
      isSelect: false,
    };
  },
  computed: {
    ...mapGetters(['getFBOrder']),
  },
  methods: {
    betTime(time) {
      return moment(new Date(time)).format('lll');
    },
    firstMatchNo() {
      return this.match.match_no * 2 - 1;
    },
    secondMatchNo() {
      return this.match.match_no * 2;
    },
    ...mapMutations(['setFBMatches', 'updateFBMatches', 'removeFBMatch', 'setWholeFBMatches']),
    checkTeam(selectedTeam, goalUpDown = null, selectedTeamId = null, soneMa = null) {
      const matchIndex = this.getFBOrder.fb_matches.findIndex(
        (match) => match.id === this.match.id,
      );

      if (selectedTeam === this.betMatch.selected_team) {
        this.betMatch = {};
        this.removeFBMatch(matchIndex);
        return false;
      }
      this.betMatch = {};
      this.betMatch = this.match;
      this.betMatch.selected_team = selectedTeam;
      this.betMatch.selected_team_id = selectedTeamId;
      if (goalUpDown && !soneMa) {
        this.betMatch.goal_up_down = goalUpDown;
        this.isGoalPor = true;
        this.isSoneMa = null;
        this.betMatch.sone_ma = null;
      } else if (!goalUpDown && soneMa) {
        this.betMatch.sone_ma = soneMa;
        this.isSoneMa = true;
        this.isGoalPor = null;
        this.betMatch.goal_up_down = null;
      } else {
        this.isGoalPor = null;
        this.betMatch.goal_up_down = null;
        this.isSoneMa = null;
        this.betMatch.sone_ma = null;
      }

      if (matchIndex === -1) this.setFBMatches(this.betMatch);
      else {
        this.updateFBMatches({
          index: matchIndex,
          value: this.betMatch,
        });
      }
      return true;
    },
    changeGoalPor() {
      this.isGoalPor = !this.isGoalPor;
      this.checkFBMatchTime();
    },
    checkFBMatchTime() {
      const matchTime = new Date(this.match.start_time);
      const nowTime = new Date();
      this.isShow = matchTime > nowTime;
      if(!this.isShow) {
        const index = this.getFBOrder.fb_matches.findIndex((match) => match.id === this.match.id)
        if(index !== -1) {
            let newDatas = JSON.parse(JSON.stringify(this.getFBOrder.fb_matches));
            newDatas.splice(index, 1);
            this.setWholeFBMatches(newDatas);
        }
      }
    },
  },
  destroyed() {
    clearInterval(this.interval);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  mounted() {
    // this.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.onMyEvent(() => {
      this.betMatch = {};
    });
    if (this.type !== 'history') {
      this.checkFBMatchTime();
      this.interval = setInterval(() => this.checkFBMatchTime(), 3000);
    }
  },
};
</script>
<style scoped>
.big-match-design {
  /* border-width: 3px !important;
  border-style: solid !important;
  border-radius: 5px !important; */
  background: rgb(177, 176, 176) !important;
  border: none !important;
  /* color: white !important; */
}
.goal-por {
    text-align: center;
}
.goal-por,
.goal-por {
    background: #575757;
    color: white;
    border-radius: 3px;
    margin-right: 100px;
    padding-bottom: 5px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    width: 100%;
    height: 34px !important;
    font-size: 12px !important;
}
.goal-por:active,
.goal-por.active {
    background: #FBBE24;
    color: black !important;
}
.kyay-left,
.kyay-right {
    width: calc(50% - 30px);
    margin: 0px;
}
.kyay-left  {
    margin-right: 5px;
}
.kyay-right  {
    margin-left: 5px;
}
.team-image {
    width: 35px;
    height: 35px;
    border-radius: 3px;
    margin: 3px;
}
.match-container {
    /* border: 1px solid rgb(191, 190, 190); */
    border-radius: 5px;
    background: #9F9F9F;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
/* .lower-card {
    border-top: 1px solid rgb(220, 217, 217);
} */
hr {
    margin-top: 0.8rem;
    margin-bottom: 0.8rem;
}

.upper-right-card,
.upper-left-card,
.upper-right-card,
.upper-left-card {
    background: #575757;
    color: white;
    border-radius: 3px;
    margin-top: 3px;
    /* margin-left: 3px;
    margin-right: 3px; */
    margin-bottom: 3px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.upper-right-card{
    margin-left: 3px;
    margin-right: 3px;
}
.upper-left-card{
    margin-left: 3px;
    margin-right: 3px;
}

.upper-right-card:active,
.upper-left-card:active,
.upper-right-card.active,
.upper-left-card.active {
    background: #FBBE24;
    color: black;
    border-radius: 3px;
    margin-top: 3px;
    /* margin-left: 3px;
    margin-right: 3px; */
    margin-bottom: 3px;
}
</style>
